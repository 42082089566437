import produce from 'immer';
import {SET_LIMIT, SET_PAGE, LIST_PRODUCTS, LIST_PRODUCTS_FAILURE} from "../actions/productActions";

const initialState = {
  list: {
    count: 0,
    success: true,
    message: null,
    data: [],
    filters: {},
    limit: 10,
    page: null
  },
  current: null,
};

export const getListProductLimit = (state) => state.product.list.limit;
export const getListProductPage = (state) => state.product.list.page;
export const getListProductTotal = (state) => state.product.list.count;
export const getProducts = (state) => state.product.list.data;
export const getListProductMessage = (state) => state.product.list.message;
export const getListProductSuccess = (state) => state.product.list.success;

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIMIT: {
      return produce(state, (draft) => {
        draft.list.limit = action.payload;
      });
    }

    case SET_PAGE: {
      return produce(state, (draft) => {
        draft.list.success = true;
        draft.list.page = action.payload;
      });
    }

    case LIST_PRODUCTS: {
      const { data, count, offset, limit, sort } = action.payload;
      return produce(state, (draft) => {
        draft.list.success = true;
        draft.list.data = data;
        draft.list.count = count;
        draft.list.page = parseInt(offset / limit);
      });
    }

    case LIST_PRODUCTS_FAILURE: {
      const { message } = action.payload;
      return produce(state, (draft) => {
        draft.list.success = false;
        draft.list.message = message;
      })
    }

    default: {
      return state;
    }
  }
};

export default productReducer;
