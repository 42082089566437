import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import {setUserData, logout, REFRESH_TOKEN} from 'src/actions/accountActions';
import authService from 'src/services/authService';
import profileService from "src/services/profileService";
import {getRefreshToken} from "../reducers/accountReducer";
import baseService from "../services/baseService";
import {SET_CONFIG} from "../actions/configActions";

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  const refreshToken = useSelector(getRefreshToken);

  const refreshAuthToken = useCallback(() => {
    setTimeout(() => {
      if (refreshToken && localStorage.getItem('accessToken')) {
        authService.refreshToken(localStorage.getItem('refreshToken'));
        refreshAuthToken();
      }
    }, 2 * 60 * 1000);
  }, [refreshToken]);

  useEffect(() => {
    if (refreshToken) {
      refreshAuthToken();
    }
  }, [refreshToken]);

  useEffect(() => {
    const initAuth = async () => {
      const config = await baseService.getConfig();

      dispatch({ type: SET_CONFIG, payload: config });
      localStorage.setItem('marketplace-config', JSON.stringify(config));

      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });

      const refresh = localStorage.getItem('refreshToken');

      try {
        if (authService.isAuthenticated() && refresh) {
          dispatch({ type: REFRESH_TOKEN, payload: { refresh } });

          await  authService.refreshToken(refresh);

          const user = await profileService.detail();
          await dispatch(setUserData(user));
        } else {
          localStorage.removeItem('accessToken');
        }
      } catch (e) {
        localStorage.removeItem('accessToken');
        dispatch({ type: REFRESH_TOKEN, payload: { refresh: null } });
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
