import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import orderReducer from "./orderReducer";
import productReducer from "./productReducer";
import categoryReducer from "./categoryReducer";
import selectProductsReducer from "./selectProductsReducer";
import customersReducer from "./customersReducer";
import configReducer from "./configReducer";
import pendingCartReducer from "./pendingCartReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  order: orderReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  product: productReducer,
  category: categoryReducer,
  customer: customersReducer,
  selectProducts: selectProductsReducer,
  pendingCart: pendingCartReducer,
  config: configReducer,
  form: formReducer
});

export default rootReducer;
