import React from 'react';
import {useSelector} from "react-redux";
import {getConfigInfo} from "../reducers/configReducer";

function Logo(props) {
  const config = useSelector(getConfigInfo);

  return (
    <img
      alt="Logo"
      src={config.image_logo}
      {...props}
    />
  );
}

export default Logo;
