import produce from 'immer';
import {SET_LIMIT, SET_PAGE, LIST_PRODUCTS} from "../actions/productActions";

const initialState = {
  list: {
    count: 0,
    data: [],
    filters: {},
    limit: 10,
    page: null,
  },
  current: null,
};

export const getListProductLimit = (state) => state.product.list.limit;
export const getListProductPage = (state) => state.product.list.page;
export const getListProductTotal = (state) => state.product.list.count;
export const getProducts = (state) => state.product.list.data;

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIMIT: {
      return produce(state, (draft) => {
        draft.list.limit = action.payload;
      });
    }

    case SET_PAGE: {
      return produce(state, (draft) => {
        draft.list.page = action.payload;
      });
    }

    case LIST_PRODUCTS: {
      const { data, count, offset, limit } = action.payload;

      return produce(state, (draft) => {
        draft.list.data = data || [];
        draft.list.count = count;
        draft.list.page = parseInt(offset / limit);
      });
    }

    default: {
      return state;
    }
  }
};

export default productReducer;
