import axios from "src/utils/axios";

class ProductService {
  list(placeSlug, limit, page, filters) {
    const params = {
      offset: page * limit,
      limit,
    };

    if (filters.stock_kind) {
      params.stock_kind = filters.stock_kind;
    }

    if (filters.category) {
      params.category_ids = filters.category;
    }

    if (filters.queryName && filters.queryName.length) {
      params.name = filters.queryName;
    }

    if (filters.availability && filters.availability === 'available') {
      params.only_in_stock = true;
    } else {
      params.only_in_stock = false;
    }

    if (filters.isActive) {
      params.only_active = true;
    }

    return axios.get(`/store/${placeSlug}/backoffice/product/`, { params });
  }

  listImages(placeSlug, productId) {
    return axios.get(`/store/${placeSlug}/client/product/${productId}/images/`);
  }

  detail(placeSlug, productId) {
    return axios.get(`/store/${placeSlug}/backoffice/product/${productId}`);
  }

  create(placeSlug, data) {
    return axios.post(`/store/${placeSlug}/backoffice/product/import-batch/`, data);
  }

  update(placeSlug, productId, data) {
    return axios.post(`/store/${placeSlug}/backoffice/product/${productId}/`, data);
  }

  updateChild(placeSlug, childId, data) {
    return axios.post(`/store/${placeSlug}/backoffice/product-child/${childId}/`, data);
  }
}

const productService = new ProductService()

export default productService;
