import _ from "lodash";
import orderService from "../services/orderService";
import productService from "../services/productService";

export const LIST_ORDERS = "@orders/set-list";
export const SET_LIMIT = "@orders/set-limit";
export const SET_PAGE = "@orders/set-page";
export const GET_ORDER = "@orders/set-detail";
export const SET_STATUS = "@orders/set-status";

export const START_SYNC_CART = "@orders/start-sync-cart";

export const SYNC_CART = "@orders/sync-cart";
export const ADD_PRODUCT = "@orders/add-product";

export const GET_SHIPPING_METHODS = "@orders/get-shipping-methods";

export const GET_PAYMENT_METHODS = "@orders/get-payment-methods";

export const FINISH_ORDER = "@orders/finish-order";

export const CLEAR_FINISH_ORDER = "@orders/clear-finish-order";

export const CLEAR_ADD_PRODUCT = "@orders/clear-add-product";

export const LOAD_PRODUCTS = "@orders/load-products";

export const CHANGE_PRODUCTS_SETTINGS = "@orders/change-products-settings";

export const SUCCESS_ADD_PRODUCT = "@orders/success-add-product";

export function listOrders(companySlug, page = 1, limit = 10, filters, sort) {
  return async (dispatch) => {
    try {
      const { data } = await orderService.list(
        companySlug,
        limit,
        page,
        filters,
        sort
      );
      dispatch({ type: LIST_ORDERS, payload: data });
    } catch (error) {
      dispatch({ type: LIST_ORDERS, payload: [] });
      throw error;
    }
  };
}

export function getOrder(companySlug, id) {
  return async (dispatch) => {
    try {
      const { data } = await orderService.detail(companySlug, id);
      dispatch({ type: GET_ORDER, payload: data });
    } catch (error) {
      dispatch({ type: GET_ORDER, payload: null });
      throw error;
    }
  };
}

export function sendAction(companySlug, actionData) {
  return async (dispatch) => {
    try {
      const { data } = await orderService.sendAction(companySlug, actionData);
      dispatch({ type: SET_STATUS, payload: data.action_type });
    } catch (error) {
      throw error;
    }
  };
}

export function fetchShippingMethods(companySlug, cartId) {
  return async (dispatch) => {
    try {
      const { data } = await orderService.getShippingMethods(
        companySlug,
        cartId
      );

      dispatch({ type: GET_SHIPPING_METHODS, payload: data });
    } catch (error) {
      dispatch({ type: GET_SHIPPING_METHODS, payload: [] });
      throw error;
    }
  };
}

export function getMissingCartItems(data, cart = []) {
  const items = {};

  cart.forEach((item) => {
    items[item.product_id] = item.product_name;
  });

  const cartItems = data?.cart_items || [];
  const invalidQuantity = cartItems.filter(
    (item) => item?.quantity?.message !== undefined
  ).map((item) => {
    return {
      actual_stock: item.quantity.actual_stock,
      stock_sent: item.quantity.stock_sent,
      product_code: item.quantity.product_code,
      product_name: items[item.quantity.product_code],
    };
  });
  return invalidQuantity;
}


export function finishOrder(companySlug, cartId, cartData, captchaToken) {
  return async (dispatch) => {
    try {
      dispatch({ type: FINISH_ORDER, payload: { success: null, error: null } });
      await orderService.updateCart(companySlug, cartId, cartData);
      const { data } = await orderService.buyCart(companySlug, cartId, captchaToken);
      dispatch({
        type: FINISH_ORDER,
        payload: {
          success: true,
          data
        }
      });
    } catch (error) {
      const itemErrors = getMissingCartItems(error.response?.data, cartData?.cart_items);
      let message = error.response?.data?.message || "Erro ao finalizar pedido, tente novamente mais tarde";
      if (itemErrors.length > 0) {
        message = "A quantidade de alguns produtos não está disponível: \n";
        message += itemErrors.map((item) => `${item.product_name} - ${Number(item.stock_sent)}/${Number(item.actual_stock)} disponíveis`).join(" | ");
      }

      dispatch({
        type: FINISH_ORDER,
        payload: {
          success: false,
          error: message
        }
      });
      throw error;
    }
  };
}

export function clearFinishOrder() {
  return async (dispatch) => {
    dispatch({ type: CLEAR_FINISH_ORDER });
  };
}

export function fetchPaymentMethods(companySlug, cartId) {
  return async (dispatch) => {
    try {
      const { data } = await orderService.getPaymentMethods(
        companySlug,
        cartId
      );
      const filteredData = data.filter(
        (item) => item.payment_type !== "online_credit_card"
      );
      dispatch({ type: GET_PAYMENT_METHODS, payload: filteredData });
    } catch (error) {
      dispatch({ type: GET_PAYMENT_METHODS, payload: [] });
      throw error;
    }
  };
}

export function addProductToCart(companySlug, cart, item) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PRODUCT,
        payload: {
          orderId: cart.id,
          item
        }
      });
      dispatch({ type: START_SYNC_CART });
      const { data } = await orderService.retrieveUpdatedCart(
        companySlug,
        cart,
        item
      );
      if (data.cart_items.length !== 0) {
        data.items = data.items.map((item) => ({
          product: item.product,
          quantity: parseFloat(item.quantity),
          location_code: item.location_code
        }));
      }
      dispatch({ type: SYNC_CART, payload: data });
      dispatch({
        type: ADD_PRODUCT,
        payload: {
          orderId: cart.id,
          item
        }
      });
      dispatch({ type: START_SYNC_CART });
      const currentOrder = getState().order.current;
      if (currentOrder) {
        const { data } = await orderService.updateCart(
          companySlug,
          cart.id,
          currentOrder
        );

        dispatch({ type: SYNC_CART, payload: data });
        dispatch({ type: SUCCESS_ADD_PRODUCT });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

const PAGE = 0;
const LIMIT = 1000;
export function loadProducts(companySlug) {
  return async (dispatch, getState) => {
    try {
      const { stockKind } = getState().order.addProduct;

      dispatch({ type: LOAD_PRODUCTS, payload: null });
      const { data } = await productService.list(companySlug, LIMIT, PAGE, {
        stock_kind: stockKind
      });
      const items = _.get(data, 'data').map((item) => {
        const colors = [];
        const sizes = [];
        const personas = [];
        const collections = [];
        item.personas = [];
        item.collections = [];

        const product_variant_children = item.product_variant_children || [];
        product_variant_children.forEach((children) => {
          children.child_attribute_values.forEach((attribute) => {
            if (attribute.attribute_code === 'COR') {
              colors.push(attribute.value)
            }

            if (attribute.attribute_code === 'TAMANHO') {
              sizes.push(attribute.value)
            }

            if (attribute.attribute_code === 'PERSONA') {
              item.personas.push(attribute.value)
              personas.push(attribute.value)
            }

            if (attribute.attribute_code === 'COLECAO') {
              item.collections.push(attribute.value)
              collections.push(attribute.value)

            }
          });
        });

        return {
          ...item,
          colors: _.uniq(colors),
          sizes: _.uniq(sizes),
          personas: _.uniq(personas),
          collections: _.uniq(collections),
          ids_categories: item.category_ids.map((category) => {
            return category.id;
          })
        }
      })

      dispatch({ type: LOAD_PRODUCTS, payload: items });
    } catch (error) {
      dispatch({ type: LOAD_PRODUCTS, payload: [] });
      throw error;
    }
  };
}
