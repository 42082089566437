import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getUserFunction } from "../reducers/accountReducer";

function FunctionGuard({ children, options }) {
  const account = useSelector((state) => state.account);
  const role = useSelector(getUserFunction);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (options && !options.includes(role)) {
    return <Redirect to="/404" />;
  }

  return children;
}

FunctionGuard.propTypes = {
  children: PropTypes.any,
  options: PropTypes.array
};

export default FunctionGuard;
