/* eslint-disable no-param-reassign */
import produce from 'immer';
import {SET_CONFIG} from "../actions/configActions";

const initialState = {
  info: {}
};

export const getConfigInfo = (state) => state.config.info;

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG: {
      return produce(state, (draft) => {
        draft.info = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default configReducer;
