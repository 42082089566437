import selectedProductsService from "../services/selectedProductsService";

export const LIST_SELECT_PRODUCTS = '@selected-products/list-selected-products';

export function listSelectProducts(companySlug, page, limit) {
  return async (dispatch) => {
    try {
      const { data } = await selectedProductsService.list(companySlug, page * limit, limit);
      dispatch({ type: LIST_SELECT_PRODUCTS, payload: data });
    } catch (error) {
      dispatch({ type: LIST_SELECT_PRODUCTS, payload: [] });
      throw error;
    }
  };
}

