import pendingCartService from "../services/pendingCartService";

export const LIST_PENDING_CARTS = '@pendingCart/set-list';
export const SET_PAGE = '@pendingCart/set-page';
export const SET_LIMIT = '@pendingCart/set-limit';



export function listPendingCarts(companySlug, page = 1, limit = 10, filters, sort) {
  return async (dispatch) => {
    try {
      const { data } = await pendingCartService.list(companySlug, limit, page, filters, sort);
      dispatch({ type: LIST_PENDING_CARTS, payload: data });
    } catch (error) {
      dispatch({ type: LIST_PENDING_CARTS, payload: [] });
      throw error;
    }
  };
}
