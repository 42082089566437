import axios from "src/utils/axios";

class PendingCartService {

  list(placeSlug, limit, page, filters, sort) {
    const params = {
      offset: page * limit,
      limit,
    };

    if (filters.search_name && filters.search_name.length) {
      params.search = filters.search_name;
    }

    if (filters.showOnlyMyClients) {
      params.show_only_my_clients = filters.showOnlyMyClients;
    }

    if (sort) {
      params.order_by = sort;
    }

    return axios.get(`/store/${placeSlug}/backoffice/pending_carts/`, { params });
  }

  notify(placeSlug, cartId) {
    return axios.post(`/store/${placeSlug}/backoffice/pending_carts/${cartId}/notify/`);
  }

}

const pendingCartService = new PendingCartService()

export default pendingCartService;
