import axios from "src/utils/axios";

class OrderService {
  list(placeSlug, limit, page, filters, sort) {
    const params = {
      offset: page * limit,
      limit,
    };

    if (filters.status) {
      params.state = filters.status;
    }

    if (sort) {
      params.order_by = sort;
    }

    return axios.get(`/store/${placeSlug}/backoffice/sale/`, { params });
  }

  detail(placeSlug, orderId) {
    return axios.get(`/store/${placeSlug}/backoffice/sale/${orderId}`);
  }

  sendAction(placeSlug, data) {
    return axios.post(`/store/${placeSlug}/backoffice/sale-action/`, data);
  }

  confirmItem(placeSlug, itemId, data) {
    return axios.put(`/store/${placeSlug}/backoffice/sale-item/${itemId}/confirmation/`, data);
  }

  getShippingMethods(placeSlug, cartId) {
    return axios.get(`/store/${placeSlug}/client/cart/${cartId}/self_shipping_methods/`, {
      'Marketplace-Code': placeSlug,
    });
  }

  getPaymentMethods(placeSlug, cartId) {
    return axios.get(`/store/${placeSlug}/client/cart/${cartId}/payment-types/`, {
      'Marketplace-Code': placeSlug,
    });
  }

  updateCart(placeSlug, cartId, data) {
    return axios.put(`/store/${placeSlug}/client/cart/${cartId}/`,
      parseCartToCartRequestBody(data), {
      'Marketplace-Code': placeSlug,
    });
  }

  retrieveUpdatedCart(placeSlug, data) {
    return axios.post(`/store/${placeSlug}/client/cart/`, {
      ...parseCartToCartRequestBody(data),
      cart_items: [],
    }, {
      'Marketplace-Code': placeSlug,
    });
  }

  buyCart(placeSlug, cartId, captchaToken) {
    return axios.post(`/store/${placeSlug}/client/cart/${cartId}/buy/`, {
      'Marketplace-Code': placeSlug,
      token: captchaToken
    });
  }


}


export const parseCartItemToCartItemRequestBody = (cartItem) => {
  const reqBody = {
    product_id: cartItem.product_id,
  }
}

export const parseCartToCartRequestBody = (cart) => {
  const reqBody = {
    store: cart.store?.slug,
    picking_type: 'delivery',
    shipping_method: cart.shipping_method,
    delivery_address: cart.delivery_address?.id,
    change_for_payment: cart.change_for_payment,
    payment_type: cart.payment_type,
    cart_items: cart.cart_items.map((item) => ({
      product_id: item.product_id,
      product_name: item.product_name,
      location_code: item.location_code ? item.location_code.toString() : null,
      quantity: item.quantity.toString(),
      suggest_price: item.suggest_price,
    })),
  };

  if (cart.id) {
    reqBody.id = cart.id;
  }

  if (cart.client) {
    reqBody.client = cart.client?.id;
  }

  if (cart.state) {
    reqBody.state = cart.state;
  }

  if (cart.expiration_date) {
    reqBody.expiration_date = cart.expiration_date;
  }

  if (cart.notes) {
    reqBody.notes = cart.notes;
  }

  return reqBody;
};


const orderService = new OrderService()

export default orderService;
