import axios from "src/utils/axios";

class SelectedProductsService {

  create(placeSlug, data) {
    return axios.post(`/store/${placeSlug}/backoffice/selected-products/`, data);
  }

  detail(placeSlug, id) {
    return axios.get(`/store/${placeSlug}/backoffice/selected-products/${id}/`);
  }

  delete(placeSlug, id) {
    return axios.get(`/store/${placeSlug}/backoffice/selected-products/${id}/`);
  }

  list(placeSlug, offset = 0, limit = 0) {
    return axios.get(`/store/${placeSlug}/backoffice/selected-products/`, { params: { offset, limit } });
  }
}

const selectedProductsService = new SelectedProductsService()

export default selectedProductsService;
