import produce from 'immer';
import {
  LIST_ORDERS, GET_ORDER, SET_STATUS, SET_LIMIT, SET_PAGE, GET_SHIPPING_METHODS, GET_PAYMENT_METHODS,
  FINISH_ORDER, CLEAR_FINISH_ORDER,
  SYNC_CART,
  ADD_PRODUCT,
  START_SYNC_CART,
  LOAD_PRODUCTS,
  CHANGE_PRODUCTS_SETTINGS,
  CLEAR_ADD_PRODUCT,
  SUCCESS_ADD_PRODUCT
} from "../actions/orderActions";

const initialState = {
  list: {
    count: 0,
    data: [],
    filters: {},
    limit: 10,
    page: null,
  },
  current: null,
  syncingCart: false,
  shippingMethods: null,
  paymentMethods: null,
  finishOrder: null,
  addProduct: {
    filters: {
      searchText: '',
      categories_ids: [],
      promotion: false,
      sizes: [],
      colors: [],
      order_by: 'newest',
      personas: [],
      collections: []
    },
    orderBy: 'newest',
    stockKind: 'client_store',
    loading: true,
    products: [],
    state: 'idle'
  }
};

export const getListOrdersPage = (state) => state.order.list.page;
export const getListOrdersTotal = (state) => state.order.list.count;
export const getOrders = (state) => state.order.list.data;
export const getCurrent = (orderId = null) => (state) => {
  if (!state.order.current && orderId) {
    return state.order.list.data.find(order => order.id === orderId);
  }
  return state.order.current;
}

export const getCurrentStatus = (state) => {
  return state.order.current ? state.order.current.state : null;
}


export const getShippingMethods = (state) => state.order.shippingMethods;

export const isErroredFinishOrder = (state) => state.order.finishOrder?.success === false;
export const isSuccessFinishOrder = (state) => state.order.finishOrder?.success === true;

export const isLoadingFinishOrder = (state) => state.order.finishOrder?.success === null;

export const getFinishOrderError = (state) => state.order.finishOrder?.error;

export const getPaymentMethods = (state) => state.order.paymentMethods;

export const isSyncingCart = (state) => state.order.syncingCart;

export const isProductsLoading = (state) => state.order.addProduct.loading;

export const getStockKind = (state) => state.order.addProduct.stockKind;

export const getProducts = (state) => state.order.addProduct.products;

export const getProductsFilters = (state) => state.order.addProduct.filters;

export const getAddProductState = (state) => state.order.addProduct.state;

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ORDERS: {
      const { results, count, offset, limit } = action.payload;

      return produce(state, (draft) => {
        draft.list.data = results || [];
        draft.list.count = count;
        draft.list.page = parseInt(offset / limit);
      });
    }
    case SET_LIMIT: {
      return produce(state, (draft) => {
        draft.list.limit = action.payload;
      });
    }

    case SET_PAGE: {
      return produce(state, (draft) => {
        draft.list.page = action.payload;
      });
    }
    case GET_ORDER: {
      const order = action.payload;

      return produce(state, (draft) => {
        draft.current = order;
      });
    }
    case SET_STATUS: {
      const status = action.payload;

      return produce(state, (draft) => {
        draft.current.state = status;
      });
    }

    case GET_SHIPPING_METHODS: {
      return produce(state, (draft) => {
        draft.shippingMethods = action.payload;
      }
      );
    }
    case GET_PAYMENT_METHODS: {
      return produce(state, (draft) => {
        draft.paymentMethods = action.payload;
      }
      );
    }

    case FINISH_ORDER: {
      return produce(state, (draft) => {
        draft.finishOrder = action.payload;
      }
      );
    }

    case CLEAR_FINISH_ORDER: {
      return produce(state, (draft) => {
        draft.finishOrder = null;
      }
      );
    }


    case CLEAR_ADD_PRODUCT: {
      return produce(state, (draft) => {
        draft.addProduct = initialState.addProduct;
      }
      );
    }
    case SUCCESS_ADD_PRODUCT: {
      return produce(state, (draft) => {

        if (draft.addProduct.state === 'loading') {
          draft.addProduct.state = 'success';
        }
      }
      );
    }
    case SYNC_CART: {

      return produce(state, (draft) => {
        draft.current = {
          ...draft.current,
          ...action.payload,
          cart_items: action.payload.cart_items,
          client: draft.current?.client,
          total: action.payload.cart_items.reduce((acc, item) => acc + item.total, 0)
        };
        draft.syncingCart = false;
      });
    }


    case START_SYNC_CART: {
      return produce(state, (draft) => {
        draft.syncingCart = true;
      });
    }

    case ADD_PRODUCT: {
      const { item } = action.payload;
      const { product, location_code, quantity } = item || {};

      return produce(state, (draft) => {
        if (draft.current && draft.current.cart_items) {
          const isAdding = product !== undefined && product !== null;
          if (isAdding) {
            draft.current.cart_items.push({
              location_code,
              quantity,
              product_id: product?.product_child?.id,
              product_name: product?.product_child?.name,
              suggest_price: product?.product_child?.price?.toFixed(2),
            });
            draft.addProduct.state = 'loading';
          } else {
            draft.current.cart_items = draft.current.cart_items.map((i) => {
              if (i.id === item.id) {
                return item;
              }
              return i;
            }
            ).filter(i => i.quantity > 0);
          }
          draft.syncingCart = false;
        }
      }
      );
    }
    case LOAD_PRODUCTS: {
      return produce(state, (draft) => {
        if (action.payload !== undefined && action.payload !== null) {
          draft.addProduct.products = action.payload;
          draft.addProduct.loading = false;

        } else {
          draft.addProduct.products = [];
          draft.addProduct.loading = true;
        }
      }
      );
    }
    case CHANGE_PRODUCTS_SETTINGS: {
      return produce(state, (draft) => {
        if (action.payload !== undefined || action.payload !== null) {
          if (action.payload.filters) {
            draft.addProduct.filters = action.payload.filters;
          }
          if (action.payload.orderBy) {
            draft.addProduct.orderBy = action.payload.orderBy;
          }
          if (action.payload.stockKind) {
            draft.addProduct.stockKind = action.payload.stockKind;
          }
        }
      }
      );
    }
    default: {
      return state;
    }
  }
};

export default orderReducer;
