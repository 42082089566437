import produce from 'immer';
import {LIST_SELECT_PRODUCTS} from "../actions/selectProductsActions";

const initialState = {
  list: {
    count: 0,
    data: [],
    filters: {},
    limit: 10,
    page: null,
  },
};

export const getSelectProducts = (state) => state.select_products.list;

const selectProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SELECT_PRODUCTS: {
      return produce(state, (draft) => {
        const { data, count, offset, limit } = action.payload;

        return produce(state, (draft) => {
          draft.list.data = data || [];
          draft.list.count = count;
          draft.list.page = parseInt(offset / limit);
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default selectProductsReducer;
