import productService from "../services/productService";
import {get} from "lodash";

export const SET_LIMIT = '@products/set-limit';
export const SET_PAGE = '@products/set-page';
export const LIST_PRODUCTS = '@products/list-products';
export const LIST_PRODUCTS_FAILURE = '@products/list-products-failure';

export function listProducts(companySlug, page = 1, limit = 10, filters) {
  return async (dispatch) => {
    try {
      const { data } = await productService.list(companySlug, limit, page, filters);
      dispatch({ type: LIST_PRODUCTS, payload: data });
    } catch (error) {
      let message = 'Falha ao recuperar os produtos.';
      if (get(error, 'response.data.integration.code') === "400") {
        message += ' Código do operador não cadastrado para seu usuário';
      }

      dispatch({ type: LIST_PRODUCTS_FAILURE, payload: { message } });
      throw error;
    }
  };
}

export function setProductListLimit(limit) {
  return (dispatch) => dispatch({ type: SET_LIMIT, payload: limit });
}

export function setProductListPage(page) {
  return (dispatch) => dispatch({ type: SET_LIMIT, payload: page });
}

