import produce from 'immer';
import {LIST_CATEGORIES} from "../actions/categoryActions";

const initialState = {
  list: null
};

export const getCategories = (state) => state.category.list;
export const getCategory = (categoryId) => (state) => {
  if (!categoryId || !state.category.list) {
    return null;
  }

  const parentCategory = state.category.list.find(category => category.id === parseInt(categoryId));

  if (!parentCategory) {

  }

  return parentCategory;
}

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CATEGORIES: {
      return produce(state, (draft) => {
        draft.list = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default categoryReducer;
