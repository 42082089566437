import axios from "src/utils/axios";

class CategoryService {
  list(placeSlug) {
    return axios.get(`/store/${placeSlug}/backoffice/category/`);
  }

  create(placeSlug, data) {
    return axios.post(`/store/${placeSlug}/backoffice/category/`, data);
  }

  detail(placeSlug, categoryId) {
    return axios.get(`/store/${placeSlug}/backoffice/category/${categoryId}`);
  }

  update(placeSlug, categoryId, data) {
    return axios.put(`/store/${placeSlug}/backoffice/category/${categoryId}/`, data);
  }

  uploadImage(placeSlug, categoryId, image) {
    return axios.post(`/store/${placeSlug}/backoffice/category/${categoryId}/change-image/`, image);
  }
}

const categoryService = new CategoryService()

export default categoryService;
