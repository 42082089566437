import axios from "src/utils/axios";

class ProfileService {
  detail() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/token/me/')
        .then(
          response => {
            if (response.data.store_operator) {
              resolve(response.data);
            }

            reject('Acesso negado.');
          }
        )
        .catch(
          error => {
            reject(error);
          }
        )
    });
  }
}

const profileService = new ProfileService()

export default profileService;
