import produce from 'immer';
import {
  LIST_PENDING_CARTS, SET_PAGE, SET_LIMIT
} from "../actions/pendingCartActions";

const initialState = {
  list: {
    count: 0,
    data: [],
    filters: {},
    limit: 10,
    page: null,
  },
  current: null,
};


export const getListPendingCartsPage = (state) => state.pendingCart.list.page;
export const getListPendingCartsTotal = (state) => state.pendingCart.list.count;
export const getPendingCarts = (state) => state.pendingCart.list.data;

const pendingCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PENDING_CARTS: {
      const { results, count, offset, limit } = action.payload;

      return produce(state, (draft) => {
        draft.list.data = results || [];
        draft.list.count = count;
        draft.list.page = parseInt(offset / limit);
      });
    }
    case SET_LIMIT: {
      return produce(state, (draft) => {
        draft.list.limit = action.payload;
      });
    }

    case SET_PAGE: {
      return produce(state, (draft) => {
        draft.list.page = action.payload;
      });
    }
    default: {
      return state;
    }
  }
};

export default pendingCartReducer;
