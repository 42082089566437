import categoryService from "../services/categoryService";

export const LIST_CATEGORIES = '@categories/list-categories';

export function listCategories(companySlug) {
  return async (dispatch) => {
    try {
      const { data } = await categoryService.list(companySlug);
      dispatch({ type: LIST_CATEGORIES, payload: data });
    } catch (error) {
      dispatch({ type: LIST_CATEGORIES, payload: [] });
      throw error;
    }
  };
}

