import axios from 'src/utils/axios';
import instance from "src/utils/axios";

const appDomain = process.env.REACT_APP_DOMAIN || window.location.hostname;

class BaseService {
  getConfig = () => new Promise((resolve, reject) => {
    axios.get('/marketplace/auto-detect/',  { params: { app_domain: appDomain } })
      .then(({ data }) => {
        instance.interceptors.request.use((config) => {
          config.params = config.params || {};
          config.params['Marketplace-Code'] = data.default_code;

          return config;
        });
        resolve(data);
      }).catch((e) => reject(e))
  })
}

const baseService = new BaseService();

export default baseService;
