/* eslint-disable no-param-reassign */
import produce from 'immer';
import get from 'lodash/get';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  REFRESH_TOKEN
} from 'src/actions/accountActions';

const initialState = {
  user: null,
  refreshToken: null,
  companySlug: null,
};

export const getUser = (state) => state.account.user;
export const getUserFunction = (state) => get(state.account.user, 'store_operator.function');
export const getRefreshToken = (state) => state.account.refreshToken;
export const getCompanySlug = (state) => state.account.companySlug;

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_TOKEN: {
      const { refresh } = action.payload;

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.refreshToken = refresh;
      });
    }

    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.companySlug = user.store_operator.company.slug;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.companySlug = user.store_operator.company.slug;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
